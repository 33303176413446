
import SignInPage from "../auth/sign-in/SignInPage";

import classes from '../auth/Auth.module.css';
import Footer from '../footer/Footer';
import ErrorModal from "../../@mafi/ErrorModal";
import { useState } from "react";

const SSMain = () => {
    const [error, setError] = useState();

    const errorHandler = () => {
        setError();
      }
    return(
        <div className={classes.signInPageCenter}>
            {error && <ErrorModal onConfim={errorHandler} title='An error occured!' message='Something went wrong!' />}
            <SignInPage />
            <Footer />
        </div>
    );
}

export default SSMain;