import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

import classes from '../FailedReporter.module.css';


const FailedReportHeader = () => {
    const { t } = useTranslation();
    return(
        <Grid container spacing={0} className={classes.dashHeader}>
            <Grid item xs={0.2}>
            </Grid>
            <Grid item xs={4} className='alignLeft pt20'>
                <Typography className="font-size-25 font-bold">{t('rt-failed-report')}</Typography>
                <Typography>{t('subtitle-failed-report')}</Typography>
            </Grid>
            <Grid item xs={5} className='pt30'>
            </Grid>
            <Grid item xs={1.7} className='alignRight pr20 pt10'>
                <img className={classes.logo} src="assets/logos/logo_company_dark.png" alt="logo" />
            </Grid>
        </Grid>
    );
}

export default FailedReportHeader;