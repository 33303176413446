import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import classes from "../Analytics.module.css";


const AnalyticsIssuesCamerasByDay = () => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const chartOptions = {
    options: {
      chart: {
        animations: {
          enabled: true,
        },
        fontFamily: "inherit",
        foreColor: "inherit",
        height: "100%",
        type: "area",
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      colors: [theme.palette.primary.light, theme.palette.primary.light],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [theme.palette.primary.dark, theme.palette.primary.light],
        opacity: 0.5,
      },
      grid: {
        show: false,
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        followCursor: true,
        theme: "dark",
        x: {
          format: "MMM dd, yyyy",
        },
      },
      xaxis: {
        categories: categories,
        labels: {
          offsetY: 0,
          rotate: 0,
          style: {
            colors: "#FFF",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    },
    series: [
      {
        name: "# Events",
        data: data,
      },
    ],
  };

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("user"));
    const getIssues = async () => {
      let response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/readEventsMonth.php?group=${users.groups}`
        // `http://localhost/dashboard/readEventsMonth.php?group=${users.groups}`
      );
      let list = await response.json();

      setCategories(list.labels);
      setData(list.data);

    };

    getIssues();
  }, []);

  return (
    <Paper className={classes.ticketsByDayChart}>
      <Typography className={classes.ticketsByDayChartTitle}>
        {t("fails-by-month")}
      </Typography>
      <Typography className={classes.ticketsByDayChartChip}>
        {t("last-30-days")}
      </Typography>

      <div className="h400">
        <ReactApexChart
          className="w-full h-full"
          options={chartOptions.options}
          series={chartOptions.series}
          type={chartOptions.options.chart.type}
          height={chartOptions.options.chart.height}
        />
      </div>
    </Paper>
  );
};

export default AnalyticsIssuesCamerasByDay;
