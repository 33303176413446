import classes from './Navigation.module.css'
import { Avatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import CameraswitchOutlinedIcon from '@mui/icons-material/CameraswitchOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WebIcon from '@mui/icons-material/Web';

const Navigation = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isAdminContent, setIsAdminContent] = React.useState('');

    const toDashboard = () => {
        let path = '/dashboard';
        navigate(path);
    }

    const toServers = () => {
        let path = '/servers';
        navigate(path);
    }

    const toUsers = () => {
        let path = '/users';
        navigate(path);
    }

    const toGroups = () => {
        let path = '/groups';
        navigate(path);
    }

    const toSites = () => {
        let path = '/sites';
        navigate(path);
    }

    const toAnalitycs = () => {
        let path = '/analytics';
        navigate(path);
    }

    const toSignOut = () => {
        let path = '/signout';
        navigate(path);
    }

    const toCamerasRT = () => {
        let path = '/cameras-real-time';
        navigate(path);
    }

    const toFailedReport = () => {
        let path = '/failed-report';
        navigate(path);
    }


    const appUserName = user.displayName;
    useEffect(() => {
        if (user.role === 'admin') {
            setIsAdminContent(
                <>
                    <ListItem button onClick={toFailedReport}>
                        <ListItemAvatar>
                            <AssessmentIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                        </ListItemAvatar>
                        <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('rt-failed-report')}</span></ListItem>
                    </ListItem>
                    <ListItem button onClick={toServers}>
                        <ListItemAvatar>
                            <StorageOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                        </ListItemAvatar>
                        <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Servers')}</span></ListItem>
                    </ListItem>
                    <ListItem button onClick={toUsers}>
                        <ListItemAvatar>
                            <PeopleAltOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                        </ListItemAvatar>
                        <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Users')}</span></ListItem>
                    </ListItem>
                    <ListItem button onClick={toGroups}>
                        <ListItemAvatar>
                            <Diversity3OutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                        </ListItemAvatar>
                        <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Groups')}</span></ListItem>
                    </ListItem>
                    <ListItem button onClick={toSites}>
                        <ListItemAvatar>
                            <WebIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                        </ListItemAvatar>
                        <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Sites')}</span></ListItem>
                    </ListItem>
                </>
            );
        }
    }, [])



    return (
        <div className={classes.navigationBlock}>
            <div className="flex flex-nowrap justify-center px-16">
                <div><img src="/assets/logos/logo_saltex.png" className={classes.logoSaltex} alt='logo' /></div>
            </div>
            <div className="flex mt-16 justify-center justify-items-center">
                {/* <div></div> */}
                <Avatar src='/assets/images/avatars/servers_avatar.png' alt="Mafi Technologies" className='w-56 content-center'></Avatar>
                {/* <div></div> */}
            </div>

            <Typography className='text-white mt20'>
                {appUserName}
            </Typography>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#1e293b',
                }}
            >
                <ListItem>
                    <Typography className={classes.navTitle}>
                        {t('Dashboards')}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography className={classes.navSubTitle}>
                        {t('subtitle-nav-dash')}
                    </Typography>
                </ListItem>
                <ListItem button onClick={toDashboard} >
                    <ListItemAvatar >
                        <HomeOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                    </ListItemAvatar>
                    <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Home')}</span></ListItem>
                </ListItem>
                <ListItem button onClick={toAnalitycs}>
                    <ListItemAvatar>
                        <TroubleshootOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                    </ListItemAvatar>
                    <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('Analytics')}</span></ListItem>
                </ListItem>
                <ListItem button onClick={toCamerasRT}>
                    <ListItemAvatar>
                        <CameraswitchOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                    </ListItemAvatar>
                    <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('rt-cameras')}</span></ListItem>
                </ListItem>

                <Divider variant="inset" component="li" className={classes.dividerStyle} />
                <ListItem>
                    <Typography className={classes.navTitle}>
                        {t('Applications')}
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography className={classes.navSubTitle}>
                        {t('subtitle-nav-app')}
                    </Typography>
                </ListItem>
                {isAdminContent}
                <ListItem button onClick={toSignOut} >
                    <ListItemAvatar>
                        <LogoutOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} />
                    </ListItemAvatar>
                    <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>{t('sign-out')}</span></ListItem>
                </ListItem>

                <Divider variant="inset" component="li" className={classes.dividerStyle} />
                <ListItem sx={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    paddingBottom: 0,
                }}>
                    <Typography variant='caption'>
                        Powered by:
                    </Typography>
                    {/* <ListItemText secondary="Powered by:" /> */}
                </ListItem>
                <ListItemButton component="a" href="https://vieratech.ai" target='_blank' alignItems='center' sx={{
                    justifyContent: 'center',
                    paddingTop: 0,
                }}>
                    {/* <ListItemAvatar> */}
                    <img src="/assets/logos/viera_tech_logo_blanco.png" alt="logo" className={classes.poweredByLogo} />
                    {/* <LogoutOutlinedIcon sx={{ height: '22px', width: '22px', ml: '20px' }} /> */}
                    {/* </ListItemAvatar> */}
                </ListItemButton>
                {/* <ListItem sx={{ ml: '-20px' }}><span style={{ fontSize: "0.85rem" }}>
                        {t('Powered by')} <img src="/assets/logos/logo_0.png" alt="logo" className={classes.logoMafi} />
                    </span></ListItem> */}
            </List>
        </div>
    );
}

export default Navigation;