import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SitesAddForm = ({ onShowForm, onSave }) => {
  const [siteName, setSiteName] = useState("");
  const [serverName, setServerName] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const [isInstalled, setIsInstalled] = useState("isInstalled");

  const handleSiteName = (e) => {
    // console.log(e.target.value)
    setSiteName(e.target.value);
  };

  const handleServerName = (e) => {
    setServerName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!siteName || !serverName){
        setMessage(
            <Alert severity="error">
              {t("Please fill out the form")}
            </Alert>
          );
          return
    }
    const data = {
      siteName: siteName,
      serverName: serverName,
      isInstalled: isInstalled,
    };

    try {
      // await fetch('https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/groups.php/group/addGroup', {
      await fetch(
        "https://vieratech.ai/projects/Salient-Dashboard-PHP/api/create-site.php",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      ).then((result) => {
        if (result.status === 200) {
            onSave()
          setSiteName("");
          setServerName("");
          setMessage(
            <Alert severity="success">{t("Site created successfully")}</Alert>
          );
          setTimeout(function () {
            setMessage("");
          }, 5000);
        } else {
          setMessage(
            <Alert severity="error">
              {t("Some error occurred, Contact Tech Support")}
            </Alert>
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleIsInstalled = (event) =>{
    const isInstalledValue = event.target.value;
    setIsInstalled(isInstalledValue);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {message}
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        spacing={2}
      >
        <Grid item xs={12} class="w-full">
          <Item>
            <form onSubmit={handleSubmit}>
              <Typography className="text-lg font-bold leading-none">
                {t("Add Site")}
              </Typography>
              <div className="flex flex-col max-w-full p-24 pt-32 sm:pt-40 sm:p-32 w-full">
                <div className="flex sm:space-x-24 mb-16">
                  <TextField
                    id="title"
                    label={t("Site Name")}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleSiteName(e)}
                    value={siteName}
                    required
                  />
                  <TextField
                    id="title"
                    label={t("Server Name")}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleServerName(e)}
                    value={serverName}
                    required
                  />
                  <FormControl sx={{ mt: -3 }}>
                    <FormHelperText>{t("Organization")}:</FormHelperText>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={isInstalled}
                      label="Ticket Source"
                      onChange={handleIsInstalled}
                      sx={{
                        width: 280,
                      }}
                    >
                      <MenuItem value="Installed">Installed</MenuItem>
                      <MenuItem value="No">No Installed</MenuItem>
                      {/* {orgData.map((row) => (
                        <MenuItem value={row.name}>{row.name}</MenuItem>
                      ))} */}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <Button
                    variant="outlined"
                    color="error"
                    className=" w-1/2 mt-16"
                    aria-label="Add"
                    type="button"
                    onClick={onShowForm}
                    size="large"
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="success"
                    className=" w-1/2 mt-16"
                    aria-label="Add"
                    type="submit"
                    size="large"
                  >
                    {t("Add Site")}
                  </Button>
                </div>
              </div>
            </form>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SitesAddForm;
