import classes from "./Sites.module.css";
import { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";
import SitesHeader from "./widgets/SitesHeader";
import SitesList from "./widgets/SitesList";
import SitesAddForm from "./widgets/SitesAddForm";
import { useTranslation } from "react-i18next";

const Sites = () => {
  const [sitesList, setStitesList] = useState([]);
  const [totalSites, setTotalSites] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const getSites = async () => {
      const response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/get-sites.php?timestamp=${new Date().getTime()}`
      );

      const sites = await response.json();

      setStitesList(sites);
      setTotalSites(sites.length);
    };

    getSites();
  }, []);

  const handleSave = async () => {
    const response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/get-sites.php?timestamp=${new Date().getTime()}`
    );

    const sites = await response.json();

    setStitesList(sites);
    setTotalSites(sites.length);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className={classes.dashboardCenter}>
      <Stack direction="row" className="flex justify-evenly">
        <Grid item xs={2} className="bgSaltexColor">
          <Navigation />
        </Grid>
        <Grid container item xs={10} className="bgPage">
          <div className={classes.divCenterDash}>
            <ProfileBar />
            <SitesHeader />
            <div class="bg-white rounded-md m-7 p-7">
              {/* <GroupsNew addGroupListHandler={addGroupListHandler} /> */}
              {showForm && (
                <SitesAddForm onShowForm={handleShowForm} onSave={handleSave} />
              )}
              {!showForm && (
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleShowForm}
                >
                  {t("Add New Site")}
                </Button>
              )}
              <SitesList filteredGroups={sitesList} serversCount={totalSites} />
            </div>
          </div>
        </Grid>
      </Stack>
    </div>
  );
};

export default Sites;
