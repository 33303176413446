import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import classes from '../Dashboard.module.css';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const DashAlerts = () => {
    const { t } = useTranslation();
    const title = t('Alerts');
    const count = localStorage.getItem("failed");
    const name = t('Alerts');
    const totalName = t('TotalMount');
    const countMonth = count;
    const [open, setOpen] = useState(false);
    let camFailed = JSON.parse(localStorage.getItem('camFailed'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

    return(
        <Paper className={classes.paperDashBox}>
            <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Alerts
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Server</TableCell>
                            <TableCell align="right">Name</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {camFailed.map((row) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.server}
                            </TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
                </DialogActions>
            </BootstrapDialog>
            </div>
            <div className={classes.divTopBox}>
                <Typography
                    className={classes.titleTypoBox}
                    color="text.secondary" 
                    >
                    {title}
                </Typography>
            </div>
            <div className={classes.divCenterBox}>
                <Typography className={classes.TextCountBoxBlue} onClick={handleClickOpen}>
                {count}
                </Typography>
                <Link className={classes.subTitleBoxBlue} style={{ cursor: 'pointer' }} onClick={handleClickOpen}>{name}</Link>
            </div>
            <Typography
                className={classes.totalsButtonBox}
                color="text.secondary"
            >
                <span className={classes.truncateBox}>{totalName}</span>:
                <b className={classes.px8Box}>{countMonth}</b>
            </Typography>
        </Paper>
    );

}

export default DashAlerts;