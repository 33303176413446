/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";

import classes from "./Dashboard.module.css";
import Grid from "@mui/material/Grid";


import DashAlerts from "./widgets/DashAlerts";
import DashCamerasOff from "./widgets/DashCamerasOff";
import DashFailures from "./widgets/DashFailures";
// import DashTotalGroups from "./widgets/DashTotalGroups";
import DashTotalSites from "./widgets/DashTotalSites";
import DashNewEventsVsCamerasOff from "./widgets/DashNewEventsVsCamerasOff";
import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AuthHeader from "../services/AuthHeader";
import { useTranslation } from "react-i18next";
import PageHeader from "../pageHeader/PageHeader";


const Dashboard = () => {
  const { t } = useTranslation();
  const [weeklyIssues, setWeeklyIssues] = useState({});
  const [fixedCameras, setFixedCameras]= useState(0);
  // let totalOff = localStorage.getItem("failed");

  useEffect(() => {
    const readWeeklyEventsIssues = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      let groupsGet = 0;
      if (user) {
        groupsGet = user.groups === null || "" ? 0 : user.groups;
      }
      let response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/readEventsIssues.php?group=${groupsGet}&timestamp=${new Date().getTime()}`
          // `http://localhost/SaliendDashboardBackEndPHP/readEventsIssues.php?group=${groupsGet}`
      );
      let data = await response.json();

      setWeeklyIssues(data);

    
      // localStorage.setItem('weeklyInfo', JSON.stringify(data))
      // console.log(list);
    };

    readWeeklyEventsIssues();
    
  }, []);

  useEffect(()=> {
    const getFixedCameras = async () => {
      const response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/getCamerasRepairWeek.php?timestamp=${new Date().getTime()}`
      );

      const data = await response.json();
      
      setFixedCameras(data[0].total);
    };

    getFixedCameras();
  }, [])



  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  let isAuth = AuthHeader();
  if (isAuth === null) {
    return "";
  }

  return (
    <div className={classes.dashboardCenter}>
      <div>
        <Stack direction='row' className="flex justify-evenly">
          <Grid item xs={2} className="bgSaltexColor overflow-y-auto">
            <Navigation />
          </Grid>
          <Grid item container xs={10} className="bgPage">
            <ProfileBar />
            <PageHeader imageUrl={"assets/logos/logo_company_dark.png"}>
              <Typography className="font-size-25 font-bold">{t('Dashboard')}</Typography>
              <Typography>{t('subtitle-dashboard')}</Typography>
            </PageHeader>
            <motion.div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
              variants={container}
              initial="hidden"
              animate="show"
            >
              <motion.div variants={item}>
                <DashAlerts />
              </motion.div>
              <motion.div variants={item}>
                <DashCamerasOff />
              </motion.div>
              <motion.div variants={item}>
                <DashFailures />
              </motion.div>
              <motion.div variants={item}>
                {/* <DashTotalGroups /> */}
                <DashTotalSites />
              </motion.div>
            </motion.div>
            <div className={classes.dashSecondLine}>
              <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-24 w-full mt-32 sm:mt-16">
                <div className="flex flex-col flex-auto">
                  <div className="flex flex-col flex-auto">
                    <DashNewEventsVsCamerasOff
                      className="w-full"
                      issues={weeklyIssues.graphIssues}
                      disabled={weeklyIssues.graphDisabled}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <Typography className="font-medium" color="text.secondary">
                    {t("Overview")}
                  </Typography>
                  <div className="flex-auto grid grid-cols-4 gap-16 mt-24">
                    <div className="h-40 col-span-2 flex flex-col items-center justify-center py-32 px-4h-40 rounded-2xl bg-indigo-50 text-indigo-800">
                      <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight ">
                        {weeklyIssues.issues}
                      </Typography>
                      <Typography className="mt-4 text-sm sm:text-lg font-medium">
                        {t("new-issues")}
                      </Typography>
                    </div>

                    <div className="h-40 col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
                      <Typography className="text-5xl sm:text-7xl font-semibold leading-none tracking-tight">
                        {weeklyIssues.disabled}
                      </Typography>
                      <Typography className="mt-4 text-sm sm:text-lg font-medium">
                        {t("Total-Off-Events")}
                      </Typography>
                    </div>

                    <div className="h-40 col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
                      <Typography className="text-5xl font-semibold leading-none tracking-tight">
                          {fixedCameras}
                         
                      </Typography>
                      <Typography className="mt-4 text-sm font-medium text-center">
                        {t("Fixed")}
                      </Typography>
                    </div>

                    <div className="h-40 col-span-2 flex flex-col items-center justify-center py-32 px-4 rounded-2xl bg-green-50 text-green-800">
                      <Typography className="text-5xl font-semibold leading-none tracking-tight">
                        {weeklyIssues.failed}
                      </Typography>
                      <Typography className="mt-4 text-sm font-medium text-center">
                        {t("fix-pending")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Stack>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Dashboard;
