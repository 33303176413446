import axios from "axios";

const API_URL = 'https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/';

const signup = (email, password) => {
    return axios
        .post(API_URL + 'user.php/user/signup',{
            email,
            password,
        })
        .then((response)=>{
            if(response.data.accessToken){
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const login = (email, password) => {
    return new Promise(function (resolve, reject) {
        fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/readUsers.php?timestamp=${new Date().getTime()}`)
        // fetch('http://localhost/dashboard/readUsers.php')
          .then((response) => response.json())
          .then((jsonData) => {
            jsonData.forEach(function(element) {
                if(element.email === email && element.password === password){
                    delete element.password;
                    localStorage.setItem("user", JSON.stringify(element));
                    resolve(true);
                } 
            });
            resolve(false);
        });
    });
}
const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("salientCameras");
    localStorage.removeItem("columns");
    localStorage.removeItem("rows");
    localStorage.removeItem("failed");
    localStorage.removeItem("disabled");
    localStorage.removeItem("camFailed");
    localStorage.removeItem("camDisabled");
    localStorage.removeItem("camTotal");
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const authService = {
    signup,
    login,
    logout,
    getCurrentUser,
};

export default authService;