/* eslint-disable react/jsx-no-comment-textnodes */
import Navigation from '../navigation/Navigation';
import ProfileBar from '../profileBar/ProfileBar';

import classes from './Servers.module.css';
import Grid from '@mui/material/Grid';
import ServersHeader from './widgets/ServersHeader';

import Footer from '../footer/Footer';

import { useTheme } from '@mui/material/styles';
import ServersList from './widgets/ServersList';
import { useState } from 'react';
import ServersNew from './widgets/ServersNew';
import AuthHeader from '../services/AuthHeader';
import { Stack } from '@mui/material';

let serverData = [];
let serverRows = [];
let serversCount = 0;

/** servers */
function createData(name, IP, port) {
  return { name, IP, port };
}

const readSalientServers = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let groupsGet = 0;
  if (user) {
    groupsGet = user.groups === null || "" ? 0 : user.groups;
  }
  return new Promise(function (resolve, reject) {
    fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/readServers.php?groups=${groupsGet}&timestamp=${new Date().getTime()}`)
      // fetch('http://localhost/dashboard/readServers.php')
      .then((response) => response.json())
      .then((jsonData) => {
        serverData = jsonData;
        resolve();
      });
  });
};

readSalientServers().then(function () {
  serverData.forEach(function (server) {
    serverRows.push(createData(server.serverName, server.serverIp, server.serverPort));
    // eslint-disable-next-line no-plusplus
    serversCount++;
  });
});



const Servers = () => {
  const theme = useTheme();
  const [serverVal, setServerVal] = useState(serverRows);
  const [serverCount, setServerCount] = useState(serversCount)

  const addServerListHandler = (server) => {
    setServerVal(prevServers => {
      let contTemp = prevServers.length + 1;
      setServerCount(contTemp);
      return [server, ...prevServers];
    });
  };

  let isAuth = AuthHeader();
  if (isAuth === null) {
    return ('');
  }

  return (
    <div className={classes.dashboardCenter}>
      {/* <div> */}
      <Stack direction='row' className="flex justify-evenly">
        <Grid item xs={2} className='bgSaltexColor'>
          <Navigation />
        </Grid>
        <Grid container item xs={10} className='bgPage'>
          <div className={classes.divCenterDash} >
            <ProfileBar />
            <ServersHeader />
            <div class="bg-white rounded-md m-7 p-7">
              <ServersNew addServerListHandler={addServerListHandler} />
              <ServersList filteredServers={serverVal} serversCount={serverCount} />
            </div>
          </div>
        </Grid>
      </Stack>
      {/* </div> */}
      {/* <Footer /> */}
    </div>

  );
}

export default Servers;