import { redirect } from "react-router-dom";

const AuthHeader = () => {
    const toLocation = (location) =>{ 
        let path = location; 
        window.location.href = path;
        return null;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    let url = window.location.href;
    url = url.split('/');
    const currentComponent = url.slice(-1);

    if(user && user.accessToken){
        if(user.role === 'staff' && (currentComponent[0] === 'users' || currentComponent[0] === 'servers' || currentComponent[0] === 'groups')){
            toLocation('/dashboard');
        } 
    } else {
        toLocation('/');
    }
}

export default AuthHeader;