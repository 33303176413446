import UsersAddForm from "./UsersAddForm";
import { useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const UsersNew = (props) => {
  const { t } = useTranslation();
  const hiddeNewUserPopUp = () => {
    setNewUserPopUp(addUserButton);
  };

  const showAddUserPopUp = () => {
    setNewUserPopUp(
      <div>
        <UsersAddForm
          onCalcelSaveExp={hiddeNewUserPopUp}
          addUserListHandler={addUserListHandler}
        />
      </div>
    );
  };

  const addUserListHandler = (newUserData) => {
    props.addUserListHandler(newUserData);
  };

  const addUserButton = (
    <Button variant="outlined" color="success" onClick={showAddUserPopUp}>
      {t("Add New User")}
    </Button>
  );
  const [newUserPopUp, setNewUserPopUp] = useState(addUserButton);

  return <div class="my-8">{newUserPopUp}</div>;
};

export default UsersNew;
