const readSalientCamerasRealTime = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let groupsGet = 0;
  if (user) {
    groupsGet = user.groups === null || "" ? 0 : user.groups;
  }


  let url = `https://vieratech.ai/projects/Salient-Dashboard-PHP/cronReadCameras.php?groups=${groupsGet}&timestamp=${new Date().getHours()}`;
  // let url = `https://vieratech.ai/projects/Salient-Dashboard-PHP/cronReadCameras.php?groups=${groupsGet}&timestamp=${new Date().getTime()}`;
  // let url = `https://vieratech.ai/projects/Salient-Dashboard-PHP/cronReadCameras.php?groups=${groupsGet}`;
  // let url = `https://vieratech.ai/projects/Salient-Dashboard-PHP/populateDashboard.php?groups=${groupsGet}&timestamp=${new Date().getHours()}`;
  return new Promise(function (resolve, reject) {
    fetch(url)
      .then((response) => response.json())
      .then((jsonData) => {
        let salientCameras = jsonData;
        //console.log(jsonData);
        // localStorage.setItem("salientCameras", JSON.stringify(salientCameras));
        let columns = salientCameras?.columns ? salientCameras?.columns : 0;
        localStorage.setItem("columns", JSON.stringify(columns));
        let rows = salientCameras.rows;
        localStorage.setItem("rows", JSON.stringify(rows));
        let failed = salientCameras.failed ? salientCameras.failed : 0;
        localStorage.setItem("failed", JSON.stringify(failed));
        let disabled = salientCameras?.disabled ? salientCameras?.disabled : 0;
        localStorage.setItem("disabled", JSON.stringify(disabled));
        if (failed === 0 || jsonData.length === 0) {
          localStorage.setItem(
            "camFailed",
            '[{"id": 1, "server": "No Alerts", "name": "", "status": ""}]'
          );
        } else {
          let camFailed = salientCameras.camFailed
            ? salientCameras.camFailed
            : 0;
          localStorage.setItem("camFailed", JSON.stringify(camFailed));
        }
        if (disabled === 0) {
          localStorage.setItem(
            "camDisabled",
            '[{"id": 1, "server": "No Alerts", "name": "", "status": ""}]'
          );
        } else {
          let camDisabled = salientCameras.camDisabled ? salientCameras.camDisabled : 0;
          localStorage.setItem("camDisabled", JSON.stringify(camDisabled));
        }
        let camTotal = salientCameras.camTotal ? salientCameras.camTotal : 0;
        localStorage.setItem("camTotal", JSON.stringify(camTotal));

        resolve();
      });
  });
};

const populateRealTime = {
    readSalientCamerasRealTime,
};

export default populateRealTime;
