import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SitesList = (props) => {
  const { t } = useTranslation();
  return (
    <div class="w-full px-16">
      <Typography class="text-xs font-bold mt-4 text-left">
        {t("Sites List")} - Total: {props.serversCount}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", marginTop: "20px" }}
      >
        <Table aria-label="customized table" class="w-full">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Site Name")}</StyledTableCell>
              <StyledTableCell align="right">
                {t("Server Name")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {t("Is Installed")}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.filteredGroups.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.siteName}
                </StyledTableCell>
                <StyledTableCell align="right">{row.serverName}</StyledTableCell>
                <StyledTableCell align="right">{row.isInstalled}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SitesList;
