import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import classes from '../Analytics.module.css'

const AnalyticsOnlineCameras = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const camTotal = localStorage.getItem('camTotal');
    const failed = localStorage.getItem('failed');
    const amount = camTotal - failed;
    const chartOptions = {
        chart: {
          animations: {
            speed: 400,
            animateGradually: {
              enabled: false,
            },
          },
          fontFamily: 'inherit',
          foreColor: 'inherit',
          height: '100%',
          width: '100%',
          type: 'area',
          sparkline: {
            enabled: true,
          },
        },
        colors: [theme.palette.success.main],
        fill: {
          colors: [theme.palette.success.light],
          opacity: 0.5,
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          followCursor: true,
          theme: 'dark',
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        grid: {
            show: true,
            borderColor: theme.palette.divider,
            padding: {
              top: 10,
              bottom: 15,
              left: 0,
              right: 0,
            },
            position: 'back',
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
        series: [
            {
              name: t('Online'),
              data: [amount, amount]
            }
          ]
      };

      return(
        <Paper className={classes.totalTicketsChart}>
            <div>
                <Typography className={classes.totalTicketsTitle}>
                    {t('Online')}
                </Typography>
            </div>
            <div>
                <Typography className={classes.totalTicketsAmountDiv}>
                    {amount.toLocaleString('en-US')}
                </Typography>
            </div>
            <div >
                <ReactApexChart 
                    options={chartOptions}
                    series={chartOptions.series}
                    type={chartOptions.chart.type}
                    height={chartOptions.chart.height}
                />
            </div>
        </Paper>
      );
}

export default AnalyticsOnlineCameras;