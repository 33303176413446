import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import classes from '../Analytics.module.css'

const AnalyticsFailedCameras = (props) => {
  const { t } = useTranslation();
    const theme = useTheme();
    const amount = localStorage.getItem('failed');
    const chartOptions = {
        chart: {
          animations: {
            speed: 400,
            animateGradually: {
              enabled: false,
            },
          },
          fontFamily: 'inherit',
          foreColor: 'inherit',
          height: '100%',
          width: '100%',
          type: 'area',
          sparkline: {
            enabled: true,
          },
        },
        colors: [theme.palette.warning.main],
        fill: {
          colors: [theme.palette.warning.light],
          opacity: 0.5,
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          followCursor: true,
          theme: 'dark',
        },
        xaxis: {
          type: 'category',
          categories: [],
        },
        grid: {
          show: true,
          borderColor: theme.palette.divider,
          padding: {
            top: 10,
            bottom: 15,
            left: 0,
            right: 0,
          },
          position: 'back',
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        series: [
            {
              name: t('Failed'),
              data: [amount, amount]
            }
          ]
      };

      return(
        <Paper className={classes.totalTicketsChart}>
            <div>
                <Typography className={classes.totalTicketsTitle}>
                    {t('Failed')}
                </Typography>
            </div>
            <div>
                <Typography className={classes.totalTicketsAmountDiv}>
                    {amount.toLocaleString('en-US')}
                </Typography>
            </div>
            <div >
                <ReactApexChart 
                    options={chartOptions}
                    series={chartOptions.series}
                    type={chartOptions.chart.type}
                    height={chartOptions.chart.height}
                />
            </div>
        </Paper>
      );
}

export default AnalyticsFailedCameras;