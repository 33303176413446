import Navigation from '../navigation/Navigation';
import ProfileBar from '../profileBar/ProfileBar';

import classes from './FailedReporter.module.css';
import Grid from '@mui/material/Grid';
import CamerasRealTimeHeader from './widgets/FailedReportHeader';
import { motion } from 'framer-motion';
import FailedReportWidget from './widgets/FailedReportWidget';
import { Stack } from '@mui/material';


const FailedReporter = () => {
    const container = {
        show: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 },
    };
    return (
        <div className={classes.failedReporterCenter}>
            {/* <div> */}
            <Stack direction='row' className="flex justify-evenly">
                <Grid item xs={2} className='bgSaltexColor'>
                    <Navigation />
                </Grid>
                <Grid item container xs={10} className='bgPage'>
                    <div className={classes.divCenterDash} >
                        <ProfileBar />
                        <CamerasRealTimeHeader />
                        <div class='grid grid-cols-3 gap-4'>
                            <div class='col-span-3'>
                                <div className={classes.ticketsByDay}>
                                    <motion.div
                                        className="grid grid-cols-1 sm:grid-cols-6 gap-24 w-full min-w-0 p-24"
                                        variants={container}
                                        initial="hidden"
                                        animate="show"
                                    >
                                        <motion.div variants={item} className="sm:col-span-6">
                                            <FailedReportWidget />
                                        </motion.div>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Stack>
            {/* </div> */}
            {/* <Footer /> */}
        </div>
    );
}

export default FailedReporter;