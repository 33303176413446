import Dashboard  from '../dashboard/Dashboard';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SSMain from "../ssMain/SSMain";
import Analytics from '../analytics/Analytics';
import Users from '../users/Users';
import '../../app-utilities.css';
import Servers from '../servers/Servers';
import CamerasRealTime from '../camerasRealTime/CamerasRealTime';
import Groups from '../groups/Groups';
import Sites from '../sites/Sites';
import axios from 'axios';
import FailedReporter from '../failedReporter/FailedReporter';

/**
 * Axios HTTP Request defaults
 */
axios.defaults.baseURL = 'https://salientDashboardPM.vieratech.ai/projects/Salient-Dashboard-PHP/rest';
// axios.defaults.baseURL = 'http://localhost/dashboard/rest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; application/json; charset=UTF-8; */*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Accept, X-Auth-Token, Origin, Application, Access-Control-Allow-Headers, Authorization, X-Requested-With';


const router = createBrowserRouter([
    { path: '/', element: <SSMain />},
    { path: '/dashboard', element: <Dashboard />},
    { path: '/analytics', element: <Analytics />},
    { path: '/cameras-real-time', element: <CamerasRealTime />},
    { path: '/servers', element: <Servers />},
    { path: '/users', element: <Users />},
    { path: '/groups', element: <Groups />},
    { path: '/sites', element: <Sites />},
    { path: '/signout', element: <SSMain />},
    { path: '/failed-report', element: <FailedReporter />},
    { path: '/*', element: <SSMain />},
]);

const App = () => {
    return(
        <RouterProvider router={router} /> 
    );   
}

export default App;