import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

import classes from './PageHeader.module.css';


const PageHeader = (props) => {
    return (
        <Grid container spacing={0} className={classes.dashHeader}>
            <Grid item xs={0.2}>
            </Grid>
            <Grid item xs={4} className='alignLeft pt20'>
                {props.children}
            </Grid>
            <Grid item xs={5} className='pt30'>
            </Grid>
            <Grid item xs={1.7} className='alignRight pr20 pt10'>
                <img className={classes.logo} src={props.imageUrl} alt="logo" />
            </Grid>
        </Grid>
    );
}

export default PageHeader;