import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import jwtService from '../../../auth/services/jwtService';
import { Alert } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import BorderOuterOutlinedIcon from '@mui/icons-material/BorderOuterOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ServersAddForm = (props) => {
    const { t } = useTranslation();
    const [serverName, setServerName] = React.useState('');
    const [serverPort, setServerPort] = React.useState('');
    const [serverIP, setServerIP] = React.useState('');
    const [message, setMessage] = React.useState('');
 
  
    const handleSubmitServer = async (e) => {
      e.preventDefault();
      const serverData = {
        name: serverName,
        IP: serverIP,
        port: serverPort
      };
      console.log(JSON.stringify({ serverData }));
  
      try {
        // await fetch(
        //   'https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/user.php/user/addUser',
        //   {
        //     method: 'POST',
        //     body: JSON.stringify(userData),
        //   }
        // ).then((result) => {
        //   if (result.status === 200) {
        //     setUserName('');
        //     setUserEmail('');
        //     setUserPasswd1('');
        //     setUserPasswd2('');
        //     setMessage('User created successfully');
        //     setOpen(true);
        //     setChecked([true, false]);
        //     props.addUserListHandler(userData);
        //   } else {
        //     setMessage(<Alert severity="error">Some error occured</Alert>'');
        //   }
        // });
        setServerName('');
        setServerIP('');
        setServerPort('');
        props.addServerListHandler(serverData);
        setMessage(<Alert severity="success">{t('Server created successfully')}</Alert>);
        setTimeout(function () { setMessage(''); },5000);
      } catch (err) {
        console.log(err);
      }
    };
  
    const handleServerName = (event) => {
      const serverNameText = event.target.value;
      console.log(serverNameText);
      setServerName(serverNameText);
    };

    const handleServerPort = (event) => {
      const serverPortText = event.target.value;
      console.log(serverPortText);
      setServerPort(serverPortText);
    };

    const handleServerIP = (event) => {
      const serverNameIP = event.target.value;
      console.log(serverNameIP);
      setServerIP(serverNameIP);
    };

  

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  }
  

  return (
    <Box sx={{ width: '100%' }}>
      {message}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
        <Grid item xs={12} class='w-full'>
          <Item>
            <form onSubmit={handleSubmitServer}>
              <Typography className="text-lg font-bold leading-none">{t('Add Server')}</Typography>
              <div className="flex flex-col max-w-full p-24 pt-32 sm:pt-40 sm:p-32 w-full">
                <div className="flex sm:space-x-24 mb-16">
                  <StorageIcon />
                  <TextField
                    id="title"
                    label={t('Server Name')}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleServerName(e)} 
                    value={serverName}
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <SchemaOutlinedIcon />
                  <TextField
                    id="title"
                    label={t('Port')}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleServerPort(e)}
                    value={serverPort}
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <BorderOuterOutlinedIcon />
                  <TextField
                    id="title"
                    label="IP"
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleServerIP(e)}
                    value={serverIP}
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                <Button
                      variant="outlined"
                      color="error"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="button"
                      onClick={hiddeExpPopUp}
                      size="large"
                    >
                      {t('Cancel')}
                    </Button>
                  <Button
                      variant="outlined"
                      color="success"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="submit"
                      size="large"
                    >
                      {t('Add Server')}
                  </Button>
                </div>
              </div>
            </form>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServersAddForm;
