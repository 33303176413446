import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import classes from "../Dashboard.module.css";


const DashTotalSites = () => {
  const [totalSites, setTotalSites] = useState(0);
  const navigate = useNavigate();

  const title = "Total Sites";
  const name = "Actives";
  const totalName = "Implemented";
  
  useEffect(() => {
    const getSites = async () => {
      const response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/getTotalSites.php?timestamp=${new Date().getTime()}`
      );

      const data = await response.json();

      setTotalSites(data.total);
    };

    getSites();
  }, []);

  return (
    <Paper className={classes.paperDashBox}>
      <div className={classes.divTopBox}>
        <Typography className={classes.titleTypoBox} color="text.secondary">
          {title}
        </Typography>
      </div>
      <div className={classes.divCenterBox}>
        <Typography className={classes.TextCountBoxGreen}>
          {totalSites}
        </Typography>
        {/* <Typography className={classes.subTitleBoxGreen}>{name}</Typography> */}
        <Link
          className={`${classes.subTitleBoxGreen} ${classes.TextCountBoxGreen}`}
          style={{ cursor: "pointer", color: "green" }}
          color="#9dd6a2"
          onClick={() => {
            navigate("/sites");
          }}
        >
          {name}
        </Link>
      </div>
      <Typography className={classes.totalsButtonBox} color="text.secondary">
        <span className={classes.truncateBox}>{totalName}</span>:
        <b className={classes.px8Box}>{totalSites}</b>
      </Typography>
    </Paper>
  );
};

export default DashTotalSites;
