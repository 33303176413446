import { Button, Grid, Typography } from "@mui/material";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useTranslation } from 'react-i18next';
import classes from '../Users.module.css';


const UsersHeader = () => {
    const { t } = useTranslation();
    return(
        <Grid container spacing={0} className={classes.dashHeader}>
            <Grid item xs={0.2}>
            </Grid>
            <Grid item xs={4} className='alignLeft pt20'>
                <Typography className="font-size-25 font-bold">{t('Users')}</Typography>
                <Typography>{t('subtitle-users')}</Typography>
            </Grid>
            <Grid item xs={5} className='pt30'>
            </Grid>
            <Grid item xs={2} className='alignRight pr30'>
                <img className={classes.logo} src="assets/logos/logo_company_dark.png" alt="logo" />
            </Grid>
        </Grid>
    );
}

export default UsersHeader;