import Navigation from '../navigation/Navigation';
import ProfileBar from '../profileBar/ProfileBar';

import classes from './Analytics.module.css';
import Grid from '@mui/material/Grid';
import AnalyticsHeader from './widgets/AnalyticsHeader';
import AnalyticsIssuesCamerasByDay from './widgets/AnalyticsIssuesCamerasByDay'
import Footer from '../footer/Footer';
import { Stack, Typography } from '@mui/material';
import AnalyticsOnlineCameras from './widgets/AnalyticsOnlineCameras';
import AnalyticsFailedCameras from './widgets/AnalyticsFailedCameras';
import AnalyticsDisabledCameras from './widgets/AnalyticsDisabledCameras'

const Analytics = () => {
    return (
        <div className={classes.analiticsCenter}>
            <div>
                <Stack direction='row' className="flex justify-evenly">
                    <Grid xs={2} className='bgSaltexColor'>
                        <Navigation />
                    </Grid>
                    <Grid item container xs={10} className='bgPage'>
                        <div className={classes.divCenterDash} >
                            <ProfileBar /><AnalyticsHeader />
                            <div class='grid grid-cols-3 gap-4'>
                                <div class='col-span-3'>
                                    <div className={classes.ticketsByDay}>
                                        <AnalyticsIssuesCamerasByDay />
                                    </div>
                                </div>
                                <div class='col-span-3 py-8'>
                                    <div className={classes.ticketsByDay}>
                                        <Typography class='text-lg font-medium text-left'>Cameras</Typography>
                                    </div>
                                </div>
                                <div class='col-span-1'><AnalyticsOnlineCameras /></div>
                                <div class='col-span-1'><AnalyticsFailedCameras /></div>
                                <div class='col-span-1'><AnalyticsDisabledCameras /></div>
                            </div>
                        </div>
                    </Grid>
                </Stack>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

export default Analytics;