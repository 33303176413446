import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import classes from "../Dashboard.module.css";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DashFailures = () => {
  const [cameras, setCameras] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const title = t("Fixed");
  const name = t("Total");
  const totalName = t("TotalMount");

  useEffect(() => {
    const getSites = async () => {
      const response = await fetch(
        `https://vieratech.ai/projects/Salient-Dashboard-PHP/api/getCamerasRepair.php?timestamp=${new Date().getTime()}`
      );

      const data = await response.json();

      setCameras(data);
    };

    getSites();
  }, []);

  const handleClose = () => {
    setShowModal(!showModal);
  };

  return (
    <Paper className={classes.paperDashBox}>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={showModal}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Fixed
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Server</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Updated</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cameras.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.server}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <div className={classes.divTopBox}>
        <Typography className={classes.titleTypoBox} color="text.secondary">
          {title}
        </Typography>
      </div>
      <div className={classes.divCenterBox}>
        <Typography className={classes.TextCountBoxYellow}>
          {cameras.length}
        </Typography>
        <Link
          className={classes.subTitleBoxYellow}
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          color="#e7c86b"
        >
          {name}
        </Link>
        {/* <Typography className={classes.subTitleBoxYellow}>{name}</Typography> */}
      </div>
      <Typography className={classes.totalsButtonBox} color="text.secondary">
        <span className={classes.truncateBox}>{totalName}</span>:
        <b className={classes.px8Box}>{cameras.length}</b>
      </Typography>
    </Paper>
  );
};

export default DashFailures;
