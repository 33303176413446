import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
[`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ServersList = (props) => {
  const { t } = useTranslation();
    return(
        <div class='w-full px-16'>
            <Typography class='text-xs font-bold mt-4 text-left'>{t('Server List')} - Total: {props.serversCount}</Typography>
            <TableContainer component={Paper} sx={{borderRadius: '10px', marginTop: '20px'}}>
            <Table aria-label="customized table" class='w-full'>
                <TableHead>
                <TableRow>
                    <StyledTableCell>{t('Server')}</StyledTableCell>
                    <StyledTableCell align="right">IP</StyledTableCell>
                    <StyledTableCell align="right">{t('Port')}</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.filteredServers.map((row) => (
                    <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                        {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.IP}</StyledTableCell>
                    <StyledTableCell align="right">{row.port}</StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </div>
    );
}

export default ServersList;