import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import jwtService from '../../../auth/services/jwtService';
import { Alert } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import BorderOuterOutlinedIcon from '@mui/icons-material/BorderOuterOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';

let serverData = [];
let serverRows = [];
let serversList = [];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function createData(serverId, serverName) {
  return { serverId, serverName };
}

const readSalientGroups = () => {
  return new Promise(function (resolve, reject) {
    fetch(`https://vieratech.ai/projects/Salient-Dashboard-PHP/readServers.php?timestamp=${new Date().getTime()}`)
    // fetch('http://localhost/dashboard/readServers.php')
      .then((response) => response.json())
      .then((jsonData) => {
        serverData = jsonData;
        resolve();
      });
  });
};

readSalientGroups().then(function () {
  serverData.forEach(function (group) {
    serverRows.push(createData(group.serverId, group.serverName));
  });
});

  

const GroupsAddForm = (props) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState([true, false]);
  const [groupName, setGroupName] = React.useState('');
  const [groupEmail, setGroupEmail] = React.useState('');
  const [groupServers, setGroupServers] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
 
  
    const handleSubmitGroup = async (e) => {
      e.preventDefault();
      const groupData = {
        groupNameVal: groupName,
        groupEmailVal: groupEmail,
        groupServersVal: groupServers.toString(),
      };
      const groupPublish = {
        name: groupName,
        email: groupEmail,
      }
      console.log(JSON.stringify({ groupData }));
  
      try {
        await fetch('https://vieratech.ai/projects/Salient-Dashboard-PHP/rest/groups.php/group/addGroup', {
        // await fetch('http://localhost/dashboard/rest/groups.php/group/addGroup', {
          method: 'POST',
          body: JSON.stringify(groupData),
        }).then((result) => {
          if (result.status === 200) {
            setGroupName('');
            setGroupEmail('');
            setGroupServers('');
            setMessage(t('Group created successfully'));
            setOpen(true);
            serversList = [];
            setChecked([true, false]);
            props.addGroupListHandler(groupPublish);
            setMessage(<Alert severity="success">{t('Group created successfully')}</Alert>);
          setTimeout(function () { setMessage(''); props.onCalcelSaveExp();},3500);
          } else {
            setMessage(<Alert severity="error">{t('Some error occurred, Contact Tech Support')}</Alert>);
          }
        });        
      } catch (err) {
        console.log(err);
      }
    };
  
    const handleGroupName = (event) => {
      const groupNameText = event.target.value;
      // console.log(groupNameText);
      setGroupName(groupNameText);
    };

    const handleGroupEmail = (event) => {
      const groupEmailText = event.target.value;
      // console.log(groupEmailText);
      setGroupEmail(groupEmailText);
    };

  

  const hiddeExpPopUp = () => {
    props.onCalcelSaveExp();
  }

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
    if(serversList.includes(event.target.id)){
      // serversList.remove(event.target.id);
      serversList = serversList.filter((server) => server !== event.target.id)
    } else {
      serversList.push(event.target.id)
    }
    console.log(serversList);
    setGroupServers(serversList);
  };

  let childrenServers = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      {serverRows.map((row) => (
        <FormControlLabel
          key={row.serverId.toString()}
          label={row.serverName}
          control={
            <Checkbox
              label={row.serverId.toString()}
              id={row.serverId}
              value={checked}
              onChange={handleChange3}
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {message}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
        <Grid item xs={6}>
          <Item>
            <form onSubmit={handleSubmitGroup}>
              <Typography className="text-lg font-bold leading-none">{t('Add Group')}</Typography>
              <div className="flex flex-col max-w-full p-24 pt-32 sm:pt-40 sm:p-32 w-full">
                <div className="flex sm:space-x-24 mb-16">
                  <StorageIcon />
                  <TextField
                    id="title"
                    label={t('Group Name')}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleGroupName(e)} 
                    value={groupName}
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                  <SchemaOutlinedIcon />
                  <TextField
                    id="title"
                    label={t('Email')}
                    className="flex-auto"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    onChange={(e) => handleGroupEmail(e)}
                    value={groupEmail}
                    required
                    fullWidth
                  />
                </div>
                <div className="flex sm:space-x-24 mb-16">
                <Button
                      variant="outlined"
                      color="error"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="button"
                      onClick={hiddeExpPopUp}
                      size="large"
                    >
                      {t('Cancel')}
                    </Button>
                  <Button
                      variant="outlined"
                      color="success"
                      className=" w-1/2 mt-16"
                      aria-label="Add"
                      type="submit"
                      size="large"
                    >
                      {t('Add Group')}
                  </Button>
                </div>
              </div>
            </form>
          </Item>
        </Grid>
        <Grid item xs={6} >
            <Typography className="text-lg font-bold leading-none">{t('Servers')}</Typography>
            <Typography className="text-lg leading-none">{t('Select the Servers for the new group')}</Typography>
            <div class='rounded-md bg-grey-100 mt-28 p-20'>
                {childrenServers}
            </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupsAddForm;
