import * as React from 'react';
import { useEffect, useState } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment/moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FailedReportWidget = () => {
  const [allIssues, setAllIssues] = useState({});
  const [allIssuesExcel, setAllIssuesExcel] = useState({});
  const { t } = useTranslation();

  const gridStyle = { minHeight: 550 }

const countryStyle = {
  color: '#7986cb'
}

const redColor = {
  color: '#ef9a9a'
}

const cameraStatus = [{
  id: 'disable',
  label: 'Disable'
},
{
  id: 'failed',
  label: 'Failed'
},
{
  id: 'error',
  label: 'Error'
}
];


let rows = [];

useEffect(() => {
  const readAllEventsIssues = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let groupsGet = 0;
    if (user) {
      groupsGet = user.groups === null || "" ? 0 : user.groups;
    }
    let response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/readEventsIssues.php?group=${groupsGet}&timestamp=${new Date().getTime()}`
      // `http://localhost/dashboard/readEventsIssues.php?group=${groupsGet}`
    );
    let data = await response.json();

    localStorage.setItem('allInfo', JSON.stringify(data.all))
    // console.log(data.all);
    data.all.map((row)=>(
      rows.push({id: row.id, camera: row.camera, server: row.server, event: row.event, eventdate: row.eventdate, eventdateat: row.eventdateat == null ? 'Not Fixed' : row.eventdateat})
    ));
    setAllIssues(rows);
    // console.log(rows);
  };

  readAllEventsIssues();
}, []);

let cBefore =  t('Created date is before...');
let cAfter = t('Created date is after...)');

  const columns = [
    { name: 'id', header: 'Id', defaultVisible: false, type: 'number' },
    { name: 'camera', defaultFlex: 1, minWidth: 100, header: t('Camera') },
    { name: 'server', defaultFlex: 1, minWidth: 100, header: t('Server')},
    { name: 'event', defaultFlex: 1, minWidth: 100, header: t('Event'), defaultFlex: 1,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'All',
        dataSource: cameraStatus
      },
      cellDOMProps: { style : redColor }
    },
    {
      name: 'eventdate',
      header: t('Failed Date'),
      defaultFlex: 1,
      minWidth: 200,
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the after field

        return {
          dateFormat: 'MM-DD-YYYY',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: index == 1 ? cBefore : cAfter
        }
      },
      render: ({ value, cellProps }) => {
        return moment(value).format('MM-DD-YYYY')
      }
    },
    { name: 'eventdateat', defaultFlex: 1, minWidth: 100, header: t('Fix Date') },
  ]
      
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

async function exportToExcel (allIssuesData) {
  const ws = XLSX.utils.json_to_sheet(allIssuesData);
  const wb = {Sheets:{'data':ws}, SheetNames:['data']};
  const excelBuffer = XLSX.write(wb, {bookType:'xlsx', type:'array'});
  const data = new Blob([excelBuffer],{type:fileType});
  FileSaver.saveAs(data, 'FailedCameraReport' + fileExtension);
}

function filterDataExcel(filters){
  let filtersObj = {
    camera: filters[0].value,
    server: filters[1].value,
    event: filters[2].value,
    eventdate: filters[3].value,
    eventdateat: filters[4].value,
  }
  // console.log(filtersObj);
  var newData = allIssues.filter(function(el){
    return el.camera.includes(filtersObj.camera) &&
            el.server.includes(filtersObj.server) &&
            el.event.includes(filtersObj.event)
  });
  setAllIssuesExcel(newData);
}


const filterValue = [
  { name: 'camera', operator: 'contains', type: 'string', value: '' },
  { name: 'server', operator: 'contains', type: 'string', value: '' },
  { name: 'event', operator: 'startsWith', type: 'string', value: '' },
  {
    name: 'eventdate',
    operator: 'before',
    type: 'date',
    value: ''
  },
  { name: 'eventdateat', operator: 'contains', type: 'string', value: '' }
];


  return (
    <div style={{ height: 400, width: '100%' }}>
      <Button variant='contained' 
        onClick={(e=> exportToExcel(allIssuesExcel))}
        color='primary'
        style={{cursor: 'pointer', fontSize: 14}}
        >
          Export to Excel
        </Button>
      <exportToExcel excelData={allIssues} fileName={"Excel Export"} /> 
      <ReactDataGrid
        className='mt-8'
        idProperty="id"
        style={gridStyle}
        columns={columns}
        sortable
        pagination
        defaultFilterValue={filterValue}
        dataSource={allIssues}
        onFilterValueChange={filter =>
          filterDataExcel(filter) 
        }
      />
    </div>
  );
}

export default FailedReportWidget;