import classes from "../Dashboard.module.css";
import { lighten, useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

let series = {
  "this-week": [
    {
      data: [1, 3, 2, 1],
      name: "New issues",
      type: "line",
    },
    {
      data: [2, 4, 6, 8],
      name: "Cameras OFF",
      type: "column",
    },
  ],
  "last-week": [
    {
      data: [1, 0, 2, 0, 0, 0, 0],
      name: "New issues",
      type: "line",
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0],
      name: "Cameras OFF",
      type: "column",
    },
  ],
};

let currentRange = "this-week";

const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const DashNewEventsVsCamerasOff = ({ issues, disabled }) => {
  // let series = {
  //   "this-week": [
  //     {
  //       name: "Cameras Disabled",
  //       type: "line",
  //       data: disabled,
  //     },
  //     {
  //       name: "New issues",
  //       type: "column",
  //       data: issues,
  //     },
  //   ],
  // };

  const theme = useTheme();
  const { t } = useTranslation();

  const chartOptions = {
    chart: {
      fontFamily: "inherit",
      foreColor: "inherit",
      width: "100%",
      height: "100%",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    labels,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      background: {
        borderWidth: 0,
      },
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.75,
        },
      },
    },
    stroke: {
      width: [3, 0],
    },
    tooltip: {
      followCursor: true,
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        labels: {
          offsetX: -16,
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      }
    ],
  };
  return (
    <div className="w-full">
      <Typography className="font-medium" color="text.secondary">
        {t("new-cam-off")}
      </Typography>
      <div className="w-full">
        <ReactApexChart
          className="w-full"
          options={chartOptions}
          series={series[currentRange]}
          height={320}
        />
      </div>
    </div>
  );
};

export default DashNewEventsVsCamerasOff;
