/* eslint-disable react/jsx-no-comment-textnodes */
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";

import classes from "./Users.module.css";
import Grid from "@mui/material/Grid";
import UsersHeader from "./widgets/UsersHeader";

import Footer from "../footer/Footer";

import { useTheme } from "@mui/material/styles";
import UsersList from "./widgets/UsersList";
import { useState, useEffect } from "react";
import UsersNew from "./widgets/UsersNew";
import AuthHeader from "../services/AuthHeader";
import { Stack } from "@mui/material";

const Users = () => {
  const theme = useTheme();
  const [userVal, setUserVal] = useState([]);
  const [userCount, setUserCount] = useState(0);

  const getUsers = async () => {
    const response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/readListUsers.php?timestamp=${new Date().getTime()}`
    );

    const data = await response.json();

    setUserVal(data);
    setUserCount(data.length);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const addUserListHandler = (user) => {
    setUserVal((prevUsers) => {
      return [getUsers(), ...prevUsers];
    });
  };

  let isAuth = AuthHeader();
  if (isAuth === null) {
    return "";
  }

  return (
    <div className={classes.dashboardCenter}>
      {/* <div> */}
      <Stack direction="row" className="flex justify-evenly">
        <Grid item xs={2} className="bgSaltexColor">
          <Navigation />
        </Grid>
        <Grid container item xs={10} className="bgPage">
          <div className={classes.divCenterDash}>
            <ProfileBar />
            <UsersHeader />
            <div class="bg-white rounded-md m-7 p-7">
              <UsersNew addUserListHandler={addUserListHandler} />
              <UsersList filteredUsers={userVal} usersCount={userCount} />
            </div>
          </div>
        </Grid>
      </Stack>
      {/* </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Users;
