import { Fragment, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import populateRealTime from "src/main/services/populateRealTime";
import { Backdrop, Button, CircularProgress, IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

let salientCameras = [];
// eslint-disable-next-line prefer-const

function createData(id, server, name, status, audio, light, ipStreamType) {
  return { id, server, name, status, audio, light, ipStreamType };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function refreshPage() {
  window.location.reload(false);
}

const CamerasDetailsWidget = () => {
  const [selectedServer, setSelectedServer] = useState("");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const { t } = useTranslation();
  let camerasData = [];
  let cameraCount = 0;
  let serversCount = 0;
  let servers = [];

  localStorage.removeItem("columns");
  localStorage.removeItem("rows");

  populateRealTime.readSalientCamerasRealTime().then(() => {
    setColumns(JSON.parse(localStorage.getItem("columns")));
    setRows(JSON.parse(localStorage.getItem("rows")));
    setOpen(false);
  });

  rows.forEach(function (row) {
    row.cameras.forEach(function(cameras){
      camerasData.push(createData(cameras.id, cameras.server, cameras.name, cameras.status, cameras.audio, cameras.light, cameras.ipStreamType));
      cameraCount++;
    })
    serversCount++;
    servers.push(row.cameras[0].server);
  });

  const camerasFilter = camerasData.filter((camera) => {
    if (selectedServer === "") {
      return camerasData;
    }
    serversCount = 1;
    return camera.server === selectedServer;
  });

  if (camerasFilter.length > 0) {
    cameraCount = camerasFilter.length;
  }

  const handleFilterServer = (event) => {
    // console.log(event.target.value);
    setSelectedServer(event.target.value);
  };

  const handleClose = () => {
    setOpen2(false);
  };

  const action = (
    <Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-auto">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={open2}
        autoHideDuration={6000}
        onClose={handleClose}
        message="This process will take some time depending of the number of servers."
        action={action}
      />
      <Typography className="w-full text-left pt10 font-bold mb-8">
        {t("Filter by server")}
      </Typography>
      <select
        value={selectedServer}
        onChange={handleFilterServer}
        className="w-1/4 border-solid"
      >
        <option selected value={""}>
          All
        </option>
        {servers.map((server, key) => (
          <option key={key} value={server}>
            {server}
          </option>
        ))}
      </select>
      <div className="table-responsive mt-8">
        <Typography className="text-lg font-medium tracking-tight leading-6 truncate saltexMargingButton20">
          {t("total-servers")}: {serversCount} - {t("total-cameras")}:{" "}
          {cameraCount}
        </Typography>
        <Table className="w-full min-w-full mt-8">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell key={index} align="center">
                  {t(column)}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {camerasFilter.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">{row.server}</TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="center">{row.audio}</TableCell>
                <TableCell align="center">{row.light}</TableCell>
                <TableCell align="center">{row.ipStreamType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default memo(CamerasDetailsWidget);
