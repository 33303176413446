
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Container, Typography, InputLabel, FormControl, Input, InputAdornment, Button, Grow } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import classes from '../Auth.module.css';
import SignInPage from '../sign-in/SignInPage';

const schema = yup.object().shape({
    email: yup.string().email('You must enter a valid email').required('You must enter a email'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(4, 'Password is too short - must be at least 4 chars.'),
  });

const defaultValues = {
    forgotEmail: ''
};

const ForgotPasswd = () => {
    const { t } = useTranslation();
    const [forgotEmail, setForgotEmail] = useState('');
    const [checked, setChecked] = React.useState(true);


    const { formState, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
      });
    
      const { forgotErrors } = formState;

    const authHandler = () =>{
        
    };

    const handleForgotEmail = (event) => {
        setForgotEmail(event.target.value);
    }

    const showLoginPasswd = () => {
        setNewForgotPopUp(
            false
        );
    }

    const forgotPasswdDivContent = <div>
                                        <img className={classes.logo} src="assets/logos/logo_company_dark.png" alt="logo" />
                                        <Container className={classes.signInContainer}>
                                            <Typography className="font-bold">
                                                {t('signIn-Welcome')}
                                            </Typography>
                                            <Typography className="mt10 font-bold">
                                                {t('forgot-passwd-label')}
                                            </Typography>
                                            <form onSubmit={authHandler} className={classes.signInForm}>
                                                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                                                    <InputLabel htmlFor="forgotEmail">
                                                        Email
                                                    </InputLabel>
                                                    <Input
                                                    id="forgotEmail" 
                                                    autoFocus 
                                                    type='email' 
                                                    // error={!!forgotErrors.forgotEmail}
                                                    required 
                                                    value={forgotEmail} 
                                                    onChange={handleForgotEmail}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                        <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                                                    <Button
                                                        variant="outlined"
                                                        className=" w-full mt-16"
                                                        aria-label="Login"
                                                        type="submit"
                                                        size="large"  
                                                    >
                                                        {t('reset-passwd')}
                                                    </Button>
                                                </FormControl>

                                                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard" className='pt20'>
                                                    <Button
                                                        variant="outlined"
                                                        className=" w-full mt-16"
                                                        aria-label="Login"
                                                        type="submit"
                                                        size="large"  
                                                        onClick={showLoginPasswd}
                                                    >
                                                        {t('back-to-login')}
                                                    </Button>
                                                </FormControl>
                                            </form>
                                        </Container>
                                    </div>;
    
    const [newForgotPopUp, setNewForgotPopUp] = useState(true);

    return(
        <div>
            <Grow 
                in={checked}  
                style={{ transformOrigin: '0 0 0' }}
                {...(checked ? { timeout: 1000 } : {})}>
                    {newForgotPopUp ? forgotPasswdDivContent : <div><SignInPage /></div>}
                </Grow>
        </div>
    );
}

export default ForgotPasswd;