import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import classes from '../Dashboard.module.css';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const DashCamerasOff = () => {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const title = t('CamerasOFF');
    const count = localStorage.getItem("disabled");
    const name = t('Cameras');
    const totalName = t('TotalMount');
    const countMonth = localStorage.getItem("disabled");
    let camDisabled = JSON.parse(localStorage.getItem('camDisabled'));

    const handleClose = () => {
        setShowModal(!showModal);
      };

    return(
        <Paper className={classes.paperDashBox}>
            <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={showModal}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Disabled
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Server</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {camDisabled.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.server}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
            <div className={classes.divTopBox}>
                <Typography
                    className={classes.titleTypoBox}
                    color="text.secondary"
                    >
                    {title}
                </Typography>
            </div>
            <div className={classes.divCenterBox}>
                <Typography className={classes.TextCountBoxRed}>
                {count}
                </Typography>
                <Link
          className={classes.subTitleBoxRed}
          style={{ cursor: "pointer" }}
          onClick={handleClose}
          color="#dd6161"
        >
          {name}
        </Link>
                {/* .<Typography className={classes.subTitleBoxRed}>{name}</Typography> */}
            </div>
            <Typography
                className={classes.totalsButtonBox}
                color="text.secondary"
            >
                <span className={classes.truncateBox}>{totalName}</span>:
                <b className={classes.px8Box}>{countMonth}</b>
            </Typography>
        </Paper>
    );

}

export default DashCamerasOff;