/* eslint-disable react/jsx-no-comment-textnodes */
import Navigation from "../navigation/Navigation";
import ProfileBar from "../profileBar/ProfileBar";

import classes from "./Groups.module.css";
import Grid from "@mui/material/Grid";

import Footer from "../footer/Footer";

import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import GroupsHeader from "./widgets/GroupsHeader";
import GroupsNew from "./widgets/GroupsNew";
import GroupsList from "./widgets/GroupsList";
import AuthHeader from "../services/AuthHeader";
import { Stack } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";

let groupData = [];
// eslint-disable-next-line prefer-const
let groupRows = [];
let groupCount = 0;

/** Groups */
function createData(name, email) {
  return { name, email };
}

const readSalientGroups = () => {
  return new Promise(function (resolve, reject) {
    fetch("https://vieratech.ai/projects/Salient-Dashboard-PHP/readGroups.php?")
      // fetch('http://localhost/dashboard/readGroups.php')
      .then((response) => response.json())
      .then((jsonData) => {
        groupData = jsonData;
        resolve();
      });
  });
};

readSalientGroups().then(function () {
  groupData.forEach(function (group) {
    groupRows.push(createData(group.groupName, group.groupEmail));
    // eslint-disable-next-line no-plusplus
    groupCount++;
  });
});

const Groups = () => {
  const theme = useTheme();
  const [groupVal, setGroupVal] = useState(groupRows);
  const [groupCountVal, setGroupCountVal] = useState(groupCount);

  const getGroups = async () => {
    let response = await fetch(
      `https://vieratech.ai/projects/Salient-Dashboard-PHP/readGroups.php?timestamp=${new Date().getTime()}`
    );

    let data = await response.json();

    setGroupVal(data);
    setGroupCountVal(data.length);
  };

  useEffect(() => {
    getGroups();
  }, []);

  const addGroupListHandler = (server) => {
    setGroupVal((prevGroups) => {
      return [getGroups(), ...prevGroups];
    });
  };

  let isAuth = AuthHeader();
  if (isAuth === null) {
    return "";
  }

  return (
    <div className={classes.dashboardCenter}>
      {/* <div> */}
      <Stack direction="row" className="flex justify-evenly">
        <Grid item xs={2} className="bgSaltexColor">
          <Navigation />
        </Grid>
        <Grid container item xs={10} className="bgPage">
          <div className={classes.divCenterDash}>
            <ProfileBar />
            <GroupsHeader />
            <div class="bg-white rounded-md m-7 p-7">
              <GroupsNew addGroupListHandler={addGroupListHandler} />
              <GroupsList
                filteredGroups={groupVal}
                serversCount={groupCountVal}
              />
            </div>
          </div>
        </Grid>
      </Stack>
      {/* </div> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Groups;
