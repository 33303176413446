import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Container, Typography, InputLabel, Select, MenuItem, FormControl, Input, InputAdornment, IconButton, Button, Grid, Grow } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ForgotPasswd from '../forgot-passwd/ForgotPasswd';
import { useNavigate } from 'react-router-dom/dist';
import { Alert } from '@mui/material';

import classes from '../Auth.module.css';
import authService from 'src/main/services/auth.service';
import populateLocalService from 'src/main/services/populateLocalStorage';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const languages = [
    { value: '', text: "Options" },
    { value: 'en', text: "English" },
    { value: 'es', text: "Español" }
];

const schema = yup.object().shape({
    authEmail: yup.string().email('You must enter a valid email').required('You must enter a email'),
    password: yup
        .string()
        .required('Please enter your password.')
        .min(4, 'Password is too short - must be at least 4 chars.'),
});

const defaultValues = {
    authEmail: '',
    password: '',
    remember: true,
};

const SignInPage = () => {
    authService.logout();
    const { t } = useTranslation();
    const [lang, setLang] = useState('');
    const [authEmail, setAuthEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const { formState, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { AuthErrors } = formState;

    const languageHandler = (e) => {
        setLang(e.target.value);
        // let loc = "http://localhost:3000/";
        let loc = "https://salientdashboardpm.vieratech.ai/";
        window.location.replace(loc + "?lng=" + e.target.value);
    }

    const authHandler = async (event) => {
        event.preventDefault();
        setOpen(true);
        // console.log(authEmail + ' p: ' + password);
        // navigate('/dashboard');
        try {
            await authService.login(authEmail, password).then((resAuth) => {
                if (resAuth) {
                    populateLocalService.readSalientCameras().then(() => {
                        setOpen(false);
                        navigate('/dashboard');
                    })
                }

                else {
                    setOpen(false);
                    setMessage(<Alert severity="error">User or Password incorrect</Alert>);
                    setTimeout(function () { setMessage(''); }, 5000);
                }
            },
                (error) => {
                    setOpen(false);
                    console.log(error);
                }
            );
        } catch (error) {
            setOpen(false);
            console.log(error);
        }
    };

    const handleAuthEmail = (event) => {
        setAuthEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleClickShowPassword = () => {
        console.log(showPassword);
        setShowPassword((showPassword) => !showPassword);
    }

    const showForgotPasswd = () => {
        setNewLoginPopUp(false);
    }

    const loginDivContent = <div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <img className={classes.logo} src="assets/logos/logo_company_dark.png" alt="logo" />
        <Container className={classes.signInContainer}>
            <Typography className="font-bold mt-32 text-4xl tracking-tight leading-tight">
                {t('signIn-Welcome')}
            </Typography>
            {message}
            <form onSubmit={authHandler} className={classes.signInForm}>
                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                    <InputLabel htmlFor="authEmail">
                        Email
                    </InputLabel>
                    <Input
                        id="authEmail"
                        autoFocus
                        type='email'
                        // error={!!AuthErrors.authEmail}
                        required
                        value={authEmail}
                        onChange={handleAuthEmail}
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                    <InputLabel htmlFor="passwordLogin">{t('Password')}</InputLabel>
                    <Input
                        id="passwordLogin"
                        type={showPassword ? 'text' : 'password'}
                        // error={!!AuthErrors.password}
                        required
                        onChange={handlePassword}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl sx={{ m: 1, width: '35ch' }} variant="standard">
                    <Button
                        variant="outlined"
                        className=" w-full mt-16"
                        aria-label="Login"
                        type="submit"
                        size="large"
                    >
                        {t('LOGIN')}
                    </Button>
                </FormControl>
            </form>
            <Grid container spacing={2} className={classes.signInFooter}>
                <Grid item xs={8}>
                    <FormControl sx={{ m: 1, width: '20ch' }} variant="standard">
                        <Button
                            variant="outlined"
                            className='h50'
                            aria-label="Login"
                            type="submit"
                            size="large"
                            onClick={showForgotPasswd}
                        >
                            {t('forgot-password')}
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth className='mt8'>
                        <InputLabel id="demo-simple-select-label">{t('Language')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className='font-size-12 h50'
                            value={lang}
                            label='language'
                            onChange={languageHandler}
                        >
                            {languages.map(item => {
                                return (<MenuItem key={item.value}
                                    value={item.value}>{item.text}</MenuItem>);
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    </div>;

    const [newLoginPopUp, setNewLoginPopUp] = useState(true);


    return (
        <Grow
            in={checked}
            style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 1000 } : {})}>
            {newLoginPopUp ? loginDivContent : <ForgotPasswd />}
        </Grow>
    );
}

export default SignInPage;